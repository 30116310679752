
import {defineComponent} from "vue"
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonItemOptions,
  IonItemOption,
  IonIcon,
  IonItemSliding,
  IonInput,
  modalController,
  IonFab,
  IonFabButton,
  IonBadge,
  IonListHeader,
  IonRefresher,
  IonRefresherContent,
  loadingController,
  toastController,
  alertController, IonNote
} from "@ionic/vue"
import {
  addOutline,
  checkmarkOutline,
  checkmarkCircleOutline,
  closeOutline,
  cloudUploadOutline,
  helpCircleOutline,
  personAddOutline,
  trashOutline, closeCircleOutline, downloadOutline
} from "ionicons/icons"
import EditInvited from "@/modals/EditInvited.vue";
import ImportInvitees from "@/modals/ImportInvitees.vue";
import {mapState} from "vuex";
import moment from "moment";
import http from "@/http";

export default defineComponent({
    name: "RsvpList",
    components: {
        IonPage,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonItemOptions,
        IonItemOption,
        IonIcon,
        IonItemSliding,
        IonInput,
        IonFab,
        IonFabButton,
        IonBadge,
        IonListHeader,
        IonRefresher,
        IonRefresherContent,
        IonNote
    },
    computed: {
        ...mapState(['invitees','account', 'inviteesCount'])
    },
    data() {
        return {
            icons: {
                trashOutline,
                closeOutline,
                closeCircleOutline,
                checkmarkOutline,
                checkmarkCircleOutline,
                helpCircleOutline,
                personAddOutline,
                addOutline,
                cloudUploadOutline,
                downloadOutline
            },
            search: '',
            count: null
        }
    },
    methods: {
        async editInvitee(inviteeEdit = null){
            const modal = await modalController.create({
                component: EditInvited,
                cssClass: 'edit-invited-modal',
                componentProps: {
                    inviteeEdit
                }
            })

            await modal.present()
            const {data, role} = await modal.onDidDismiss()
            if(role === 'save'){
                this.$store.dispatch('inviteeSave', data)
            }
        },
        async importInvitees(){
            const modal = await modalController.create({
                component: ImportInvitees,
                cssClass: 'import-invitees-modal'
            })

            await modal.present()
            const {data, role} = await modal.onDidDismiss()
            if(data && role === 'import'){
                const loader = await loadingController.create({
                    message: 'מעדכן מוזמנים'
                })
                await loader.present()

                const {availableSpaces, inviteesCount, createdCount, totalCount} = await this.$store.dispatch('inviteesSave', data)

                if(createdCount && totalCount){
                    await loader.dismiss()
                    await (await toastController.create({
                        message: 'מוזמנים נשמרו בהצלחה',
                        color: 'primary',
                        duration: 1500
                    })).present()
                }else if(availableSpaces && inviteesCount){
                    await loader.dismiss()

                    const alert = await alertController.create({
                        message: `עברת את מכסת השימוש, ניסית לייבא ${inviteesCount} נשארו לך ${availableSpaces} מקומות פנויים`,
                        buttons: [
                            'הבנתי'
                        ]
                    })

                    await alert.present()
                }else{
                    await loader.dismiss()
                }
            }
        },
        async searchFilter(){
            console.log(this.search)
        },
        rsvp(invitee: any, status: string){
            invitee.rsvpStatus = status
            this.$store.dispatch('inviteeSave', invitee)
        },
        async remove(invitee: any){
            if(await this.$store.dispatch('inviteeRemove', invitee)){
                await (await toastController.create({
                    message: 'מוזמן נמחק בהצלחה',
                    duration: 1000,
                    color: "primary"
                })).present()
            }else{
                await (await toastController.create({
                    message: 'מחיקת מוזמן נכשלה.',
                    duration: 1000,
                    color: "secondary"
                })).present()
            }
        },
        async doRefresh(e: any){
            await this.$store.dispatch('inviteesLoad')
            e.target.complete()
        },
        dateFormat(value: string){
            return moment(value).format('DD.MM.YYYY')
        },
        async exportToExcel(){
          const {data} = await http.get('invitees/export', {responseType: 'blob'})
          if(data){
            const url = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.href = url
            a.download = 'invitees.xlsx'
            a.click()
            window.URL.revokeObjectURL(url)
          }
        }
    },
    mounted(){
        this.$store.dispatch('inviteesLoad')
    }
})
