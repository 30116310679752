<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button @click="back">
                    <ion-icon slot="icon-only" :icon="icons.arrowForwardOutline"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title>הוספת מוזמנים</ion-title>
            <ion-buttons slot="end">
                <ion-button v-if="contactsToImport && contactsToImport.length" @click="contactsImport">
                    סיום
                    <ion-icon slot="icon-only" :icon="icons.checkmarkOutline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh" :disabled="refreshDisabled">
            <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <ion-row>
            <ion-col>
                <ion-button color="primary" @click="createNew">הוספת מוזמן חדש</ion-button>
            </ion-col>
            <ion-col>
              <ion-button color="primary" @click="loadDeviceContacts">יבוא ממכשירך</ion-button>
            </ion-col>
        </ion-row>
<!--        <ion-button color="primary">יבוא מקובץ CSV</ion-button>-->
        <div v-if="contacts && contacts.length">
            <ion-item>
                <ion-label position="floating">חיפוש</ion-label>
                <ion-input v-model="searchQuery" type="text"></ion-input>
            </ion-item>
        </div>
        <ion-list v-if="filteredContacts && filteredContacts.length">
            <ion-list-header>סמנו אנשי קשר לייבוא</ion-list-header>
            <template v-for="contact in filteredContacts" :key="contact.contactId">
                <ion-item v-for="(phone, phoneIndex) in contact.phoneNumbers" :key="phoneIndex">
                    <ion-label>
                        <h2>{{contact.displayName}}</h2>
                        <h3>{{phone.number}} <span v-if="phone.label">({{phone.label}})</span></h3>
                    </ion-label>
                    <ion-checkbox slot="end" @update:modelValue="toggleContact($event,contact.displayName, phone)"></ion-checkbox>
                </ion-item>
                <ion-item v-for="(email, emailIndex) in contact.emails" :key="emailIndex">
                    <ion-label>
                        <h2>{{contact.displayName}}</h2>
                        <h3>{{email.address}} <span v-if="email.label">({{email.label}})</span></h3>
                    </ion-label>
                    <ion-checkbox slot="end" @update:modelValue="toggleContact($event,contact.displayName, email)"></ion-checkbox>
                </ion-item>
            </template>
        </ion-list>
    </ion-content>
</template>

<script>
import {defineComponent} from "vue";
import {
    alertController,
    IonButton,
    IonButtons, IonCheckbox, IonCol,
    IonContent,
    IonHeader,
    IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonRefresher, IonRefresherContent, IonRow,
    IonTitle,
    IonToolbar, loadingController,
    modalController, toastController
} from "@ionic/vue";
import {arrowForwardOutline, checkmarkOutline} from "ionicons/icons";
import {Contacts} from "@capacitor-community/contacts";
import EditInvited from "@/modals/EditInvited";

export default defineComponent({
    name: "ImportInvitees",
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonCheckbox,
        IonRefresher,
        IonRefresherContent,
        IonInput,
        IonRow,
        IonCol
    },
    data(){
        return {
            icons: {
                arrowForwardOutline,
                checkmarkOutline
            },
            contactsToImport: [],
            contacts: [],
            refreshDisabled: true,
            searchQuery: ''
        }
    },
    computed: {
        filteredContacts(){
            return this.contacts.filter((contact) => {
                return contact.displayName.includes(this.searchQuery)
            })
        }
    },
    methods: {
        async back(){
            await modalController.dismiss()
        },
        async doRefresh(e){
            await this.loadDeviceContacts(false)
            e.target.complete()
        },
        async loadFromCache(){
            if(typeof(localStorage) !== 'undefined'){
                let lsContacts = localStorage.getItem('contacts')
                if(lsContacts){
                    lsContacts = JSON.parse(lsContacts)
                    this.refreshDisabled = false
                    this.contacts = lsContacts
                    return true
                }
            }
            return false
        },
        async loadDeviceContacts(loadCache = true){

            const loader = await loadingController.create({
                message: 'טוען'
            })
            await loader.present()

            if(loadCache){
                if(await this.loadFromCache()){
                    await loader.dismiss()
                    return
                }
            }

            try{
                const {granted, contacts} = await Contacts.getPermissions()
                if(granted || contacts === "granted"){
                    try {
                        const {contacts} = await Contacts.getContacts()
                        if(contacts && contacts.length > 0){
                            this.refreshDisabled = false
                            this.contacts = contacts
                            if(typeof(localStorage) !== 'undefined'){
                                localStorage.setItem('contacts', JSON.stringify(contacts))
                            }
                        }

                        setTimeout(async () => {
                            await loader.dismiss()
                        }, 1500);

                        await (await toastController.create({
                            message: 'אנשי קשר נטענו בהצלחה',
                            color: 'primary',
                            duration: 1500
                        })).present()

                    }catch (e){
                        await loader.dismiss()
                        console.warn(e)
                    }
                } else {
                    await (await toastController.create({
                        message: 'יש לאשר הרשאות על מנת לקרוא אנשי קשר ממכשירך',
                        color: 'secondary',
                        duration: 1500
                    })).present()
                }
            }catch (e){
                await loader.dismiss()
                console.warn(e)
            }

        },
        toggleContact(e, name, {address, number}){
            const fullName = name.split(' ');
            const firstName = fullName[0] || ''
            const lastName = fullName[1] || ''
            const existsIndex = this.contactsToImport.findIndex(c => {
                return firstName === c.firstName && lastName === c.lastName && number === c.phone && address === c.email
            })
            if(e){
                this.contactsToImport.push({
                    firstName,
                    lastName,
                    phone: number,
                    email: address
                })
            }else if(existsIndex !== -1){
                this.contactsToImport.splice(existsIndex, 1)
            }
            console.log(this.contactsToImport)
        },
        async contactsImport(){
            await modalController.dismiss(this.contactsToImport, 'import')
        },
        async createNew(inviteeEdit = null){
            const modal = await modalController.create({
                component: EditInvited,
                cssClass: 'edit-invited-modal',
                componentProps: {
                    inviteeEdit
                }
            })

            await modal.present()
            const {data, role} = await modal.onDidDismiss()
            if(role === 'save'){
                if(await this.$store.dispatch('inviteeSave', data)){
                    await (await toastController.create({
                        message: "מוזמן נשמר בהצלחה",
                        duration: 1000
                    })).present()

                    try{
                        await modalController.dismiss()
                    }catch (e){
                        console.log(e)
                    }

                    const alert = await alertController.create({
                        message: "מוזמן התווסף בהצלחה, ברצונך להוסיף עוד אחד?",
                        buttons: [
                            {
                                text: "כן",
                                role: "confirm"
                            },
                            {
                                text: "לא",
                                role: "cancel"
                            }
                        ]
                    })

                    await alert.present()
                    const {role} = await alert.onDidDismiss()
                    if(role === 'confirm'){
                        await this.createNew()
                    }
                }else{
                    await (await toastController.create({
                        message: "מוזמן לא נשמר, יש להזין שם, שם משפחה, טלפון או דוא&quot;ל",
                        duration: 5000,
                        color: "secondary"
                    })).present()
                    await this.createNew(data)
                }

            }
        },
    },
    mounted() {
        this.loadFromCache()
    }
})
</script>

<style scoped>

</style>
