
import {defineComponent} from "vue";
import {
    alertController,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon, IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, IonTextarea,
    IonTitle,
    IonToolbar,
    modalController
} from "@ionic/vue";
import {arrowForwardOutline, checkmarkOutline, shareSocialOutline} from "ionicons/icons";
import {Share} from "@capacitor/share";

export default defineComponent({
    name: "EditInvited",
    props: {
        inviteeEdit: {
            required: false,
            default: null
        }
    },
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonList,
        IonItem,
        IonInput,
        IonLabel,
        IonSelect,
        IonSelectOption,
        IonTextarea
    },
    data(){
        return {
            icons: {
                arrowForwardOutline,
                checkmarkOutline,
                shareSocialOutline
            },
            invitee: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                rsvpStatus: 'tentative',
                comment: '',
                invitationLink: null
            },
            originalInvitee: {}
        }
    },
    methods: {
        async back(){
            if(JSON.stringify(this.originalInvitee) !== JSON.stringify(this.invitee)){
                const alert = await alertController.create({
                    message: 'לצאת מבלי לשמור שינויים במוזמן זה?',
                    buttons: [
                        {
                            text: 'ביטול',
                            role: 'cancel'
                        },
                        {
                            text: 'אישור',
                            role: 'confirm'
                        }
                    ]
                })

                await alert.present()
                const {data, role} = await alert.onDidDismiss()
                if(role === 'confirm'){
                    await modalController.dismiss(data, 'cancel')
                }
            }else{
                await modalController.dismiss( null, 'cancel')
            }
        },
        async save(){
            await modalController.dismiss(Object.assign({}, this.invitee), 'save')
        },
        linkShare(url: string){
            Share.share({
                url
            })
        }
    },
    mounted() {
        if(this.inviteeEdit){
            this.invitee = Object.assign({}, this.inviteeEdit)
        }
        this.originalInvitee = Object.assign({}, this.invitee);
    }
})
